<template>
  <div>
    <div v-if="dashboard">
      <v-flex class="d-flex justify-start align-center">
        <v-flex class="font-weight-black" >DASHBOARD</v-flex>
        <span class="subtitle-2 mr-2">
          Tanggal Transaksi
        </span>
        <DateRangePicker
            v-model="datePickerRangeInv"
            :locale-data="dprLocale"
            @update="changeDatePickerRange"
            :autoApply="dprAutoApply"
            :showDropdowns="dprShowDropdowns"
        ></DateRangePicker>
        <DateRangePicker
            v-if="false"
            disabled
            class="ml-1 grey--text text--lighten-2"
            v-model="datePickerRangeInv"
            :locale-data="dprLocale"
            @update="changeDatePickerRange"
            :autoApply="dprAutoApply"
            :showDropdowns="dprShowDropdowns"
        ></DateRangePicker>
        <v-spacer></v-spacer>
      </v-flex>
    </div>

    <div v-if="salesFilter">
      <v-row v-if=" ! haveRoleCSOnly">
        <v-col
            cols="12"
            sm="4"
            md="4"
        >
          <v-autocomplete
              v-model="filterFdivisions"
              :items="itemsFDivision"
              item-value="id"
              item-text="description"
              dense
              chips
              small-chips
              deletable-chips
              clearable
              label="Distributor"
              multiple
              hide-details
          ></v-autocomplete>
        </v-col>
        <v-col
            cols="12"
            sm="4"
            md="4"
        >
          <v-autocomplete
              v-model="filterFsalesmans"
              :items="itemsFSalesman"
              item-value="id"
              item-text="spname"
              dense
              chips
              small-chips
              deletable-chips
              clearable
              label="Salesman/CS"
              multiple
              hide-details
          ></v-autocomplete>
        </v-col>

        <v-col
            cols="12"
            sm="4"
            md="4"
        >
          <v-autocomplete
              v-model="filterFwarehouses"
              :items="itemsFWarehouseActive"
              item-value="id"
              item-text="description"
              auto-select-first
              dense
              chips
              small-chips
              deletable-chips
              color="blue-grey lighten-2"
              label="Warehouse"
              persistent-hint
              multiple
              hide-details
          >
            <template v-slot:selection="data">
              <v-chip
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  close
                  @click="data.select"
                  @click:close="removeAutoCompleteFWarehouse(data.item)"
              >
                <v-avatar left>
                  <v-img :lazy-src="lookupImageUrlLazy(data.item)" :src="lookupImageUrl(data.item)"></v-img>
                </v-avatar>
                <span class="font-weight-regular caption"> {{ data.item.kode1 }} </span>
              </v-chip>
            </template>

            <template v-slot:item="data">
              <template v-if="typeof data.item !== 'object'">
                <v-list-item-content v-text="data.item"></v-list-item-content>
              </template>
              <template v-else>
                <v-list-item-avatar>
                  <v-img :lazy-src="lookupImageUrlLazy(data.item)" :src="lookupImageUrl(data.item)"></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{data.item.description}}</v-list-item-title>
                  <v-list-item-subtitle>{{data.item.kode1}}</v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>

        </v-col>

      </v-row>

      <v-row>

        <v-col cols="12" sm="4" md="4">
          <v-flex class="d-flex justify-end">
            <span class="subtitle-2 mr-2">
              Invoice
            </span>
            <DateRangePicker
                v-model="datePickerRangeInv"
                :locale-data="dprLocale"
                @update="changeDatePickerRange"
                :autoApply="dprAutoApply"
                :showDropdowns="dprShowDropdowns"
            ></DateRangePicker>
          </v-flex>
        </v-col>

        <v-col
            cols="12"
            sm="4"
            md="4"
        >
          <v-autocomplete
              v-model="filterFexpedisies"
              :items="itemsFExpedisi.filter(x => x.statusActive)"
              item-value="id"
              item-text="description"
              auto-select-first
              dense
              chips
              small-chips
              deletable-chips
              color="blue-grey lighten-2"
              label="Expedisi"
              persistent-hint
              multiple
              hide-details
          >
            <template v-slot:selection="data">
              <v-chip
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  close
                  @click="data.select"
                  @click:close="removeAutoCompleteFExpedisi(data.item)"
              >
                <v-avatar left>
                  <v-img :lazy-src="lookupImageUrlLazy(data.item)" :src="lookupImageUrl(data.item)"></v-img>
                </v-avatar>
                <span class="font-weight-regular caption"> {{ data.item.kode1 }} </span>
              </v-chip>
            </template>

            <template v-slot:item="data">
              <template v-if="typeof data.item !== 'object'">
                <v-list-item-content v-text="data.item"></v-list-item-content>
              </template>
              <template v-else>
                <v-list-item-avatar>
                  <v-img :lazy-src="lookupImageUrlLazy(data.item)" :src="lookupImageUrl(data.item)"></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{data.item.description}}</v-list-item-title>
                  <v-list-item-subtitle>{{data.item.kode2}}</v-list-item-subtitle>

                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>

        </v-col>

        <v-col
            cols="12"
            sm="4"
            md="4"
        >
          <v-autocomplete
              v-model="filterEstatusPengiriman"
              :items="itemsEStatusPengiriman"
              item-value="id"
              item-text="shortDesc"
              dense
              chips
              small-chips
              deletable-chips
              clearable
              label="Status Pengiriman"
              multiple
              hide-details
          ></v-autocomplete>
        </v-col>






        <v-col cols="6" sm="2" md="2">
          <v-btn color="primary" small @click="runExtendedFilterFromChild">
            Terapkan
            <v-icon small class="ml-1" color="green lighten-2">mdi-filter</v-icon>
          </v-btn>
        </v-col>

      </v-row>


    </div>

  </div>

</template>

<script>
import FileService from "@/services/apiservices/file-service";
import {
  dateFormattedDatefns,
  dateFormattedDatefnsShort,
  dateFormattedDatefnsWithTime,
  dateFormattedDatefnsWithTimeShort
} from "@/utils/date-utils";
import {EStatusPengirimans} from "@/models/e-status-pengiriman";
import ERole from "@/models/e-role";
import FtSaleshFilter from "@/models/payload/ft-salesh-filter";

import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
  name: "FtSaleshFilterExt",
  props:{
    dashboard: Boolean,
    salesFilter: Boolean,
    itemsGroupMaterialOrdered:[]
  },
  components:{
    DateRangePicker
  },
  data(){
    return{
      itemsEStatusPengiriman: EStatusPengirimans,

      datePickerRangeInv: {
        // startDate: function(d){ d.setDate(d.getDate()-2); return d}(new Date),
        // endDate: new Date(),
        startDate: new Date(),
        endDate: new Date(),
      },
      dprLocale: {
        direction: 'ltr', //direction of text
        format: 'dd-mm-yyyy',
        separator: ' ~ ', //separator between the two ranges
        applyLabel: 'Terapkan',
        cancelLabel: 'Batal',
        weekLabel: 'W',
        customRangeLabel: 'Custom Range',
        // daysOfWeek: moment.weekdaysMin(), //array of days - see moment documenations for details
        // monthNames: moment.monthsShort(), //array of month names - see moment documenations for details
        firstDay: 1, //ISO first day of week - see moment documenations for details
      },
      dprAutoApply:true,
      dprShowDropdowns:true,

      filterFdivisions: [],
      filterFsalesmans: [],
      filterFstores: [],
      filterFexpedisies:[],
      filterFmaterials: [],
      filterEstatusPengiriman: [],
      filterFwarehouses: [],

      filterByGroupMaterialOrdered: [],
      // itemsGroupMaterialOrdered:[],

      orderDateFrom: new Date(),
      orderDateTo: new Date(),
      invoiceDateFrom: new Date(),
      invoiceDateTo: new Date()


    }
  },
  computed: {
    currentUser(){
      return this.$store.state.auth.user;
    },
    haveRoleAdminMutlak(){
      let isValid = false
      if (this.currentUser.roles != null){
        if (
            this.currentUser.roles.includes("ROLE_ADMIN")
        ){
          isValid=true
        }
      }
      return isValid
    },
    haveRoleAdmin(){
      let isValid = false
      if (this.currentUser.roles != null){
        if (
            this.currentUser.roles.includes("ROLE_ADMIN") ||
            this.currentUser.roles.includes("ROLE_ADMIN1") ||
            this.currentUser.roles.includes("ROLE_ADMIN2")
        ){
          isValid=true
        }
      }
      return isValid
    },
    haveRoleInventory(){
      let isValid = false
      if (this.currentUser.roles != null){
        if (this.currentUser.roles.includes("ROLE_INVENTORY") ){
          isValid=true
        }
      }
      return isValid
    },
    haveRolePiutang(){
      let isValid = false
      if (this.currentUser.roles != null){
        if (this.currentUser.roles.includes("ROLE_PIUTANG") ){
          isValid=true
        }
      }
      return isValid
    },
    haveRoleSalesOrCS(){
      let isValid = false
      if (this.currentUser.roles != null){
        if (this.currentUser.roles.includes("ROLE_CS") || this.currentUser.roles.includes("ROLE_SALES") ){
          isValid=true
        }
      }
      return isValid
    },
    haveRoleCSOnly(){
      let isValid = false
      if (this.currentUser.roles != null){
        if (this.currentUser.roles.includes("ROLE_CS") ){
          isValid=true
        }
      }
      return isValid
    },

    itemsGroupMaterialOrderedComputed(){
      let newItems = []
      if (this.itemsGroupMaterialOrdered){
        newItems = this.itemsGroupMaterialOrdered
      }

      return newItems
    },

    itemsFDivision(){
      return this.$store.state.data.listFDivision
    },
    itemsFMaterial(){
      return this.$store.state.data.listFMaterial
    },
    itemsFExpedisi(){
      return this.$store.state.data.listFExpedisi
    },
    itemsFWarehouse(){
      return this.$store.state.data.listFWarehouse
    },
    itemsFWarehouseActive(){
      return this.$store.state.data.listFWarehouse.filter(x => x.statusActive===true)
    },
    itemsFSalesman(){
      const listFSalesman = []

      if (this.currentUser.salesmanOf > 0 &&  [ERole.ROLE_CS].some(x =>this.currentUser.roles.includes(x)) ) {
        this.$store.state.data.listFSalesman.filter(x => x.id  === this.currentUser.salesmanOf).forEach( item =>{
          listFSalesman.push(item)
        })
      }else {
        this.$store.state.data.listFSalesman.forEach( item =>{
          listFSalesman.push(item)
        })
      }

      return listFSalesman
    },
    itemsFStore(){
      return this.$store.state.data.listFStore
    },

    itemsFCustomer(){
      return this.$store.state.data.listFCustomer
    },
    itemsFCustomerGroup(){
      return this.$store.state.data.listFCustomerGroup
    },

  },
  methods:{
    runExtendedFilterFromChild(){
      const ftSaleshFilter = new FtSaleshFilter()

      ftSaleshFilter.fdivisionIds =  this.filterFdivisions //dilakukan pada back-end untuk security

      if (this.filterFsalesmans.length >0){
        ftSaleshFilter.fsalesmanIds = this.filterFsalesmans
      }else {
        // console.log(`>> ${JSON.stringify(this.itemsFSalesman)}`)
        ftSaleshFilter.fsalesmanIds = this.itemsFSalesman.map( item => item.id)
      }

      if (this.filterFstores.length >0){
        ftSaleshFilter.fstoreIds = this.filterFstores
      }else {
        ftSaleshFilter.fstoreIds = this.itemsFStore.map( item => item.id)
      }
      if (this.filterFexpedisies.length >0){
        ftSaleshFilter.fexpedisiIds =  this.filterFexpedisies
      }else {
        ftSaleshFilter.fexpedisiIds =  this.itemsFExpedisi.map( item => item.id)
      }
      if (this.filterEstatusPengiriman.length >0){
        ftSaleshFilter.statusPengirimanIds =  this.filterEstatusPengiriman
      }else {
        ftSaleshFilter.statusPengirimanIds = this.itemsEStatusPengiriman.map( item => item.id)
      }
      if (this.filterFmaterials.length >0){
        ftSaleshFilter.fmaterialIds =  this.filterFmaterials
      }else {
        ftSaleshFilter.fmaterialIds = this.itemsFMaterial.map( item => item.id)
      }
      if (this.filterFwarehouses.length >0){
        ftSaleshFilter.fwarehouseIds =  this.filterFwarehouses
      }else {
        ftSaleshFilter.fwarehouseIds = this.itemsFWarehouse.map( item => item.id)
      }

      // console.log(`${this.datePickerRangeInv.startDate} >> ${this.datePickerRangeInv.endDate}`)
      ftSaleshFilter.orderDateFrom =  this.datePickerRangeInv.startDate
      ftSaleshFilter.orderDateTo =  this.datePickerRangeInv.endDate
      ftSaleshFilter.invoiceDateFrom =  this.datePickerRangeInv.startDate
      ftSaleshFilter.invoiceDateTo =  this.datePickerRangeInv.endDate

      // ftSaleshFilter.pageNo = this.currentPage
      // ftSaleshFilter.pageSize = this.pageSize
      // ftSaleshFilter.sortBy = "id"
      // ftSaleshFilter.order = "DESC"
      // ftSaleshFilter.search = this.search

      ftSaleshFilter.listRemark = this.filterByGroupMaterialOrdered
      // console.log(this.filterByGroupMaterialOrdered)

      this.$emit('runExtendedFilterFromChild', ftSaleshFilter)

    },
    changeDatePickerRange(){
      console.log(`${this.datePickerRangeInv.startDate} >> ${this.datePickerRangeInv.endDate}`)
      this.orderDateFrom =  this.datePickerRangeInv.startDate
      this.orderDateTo =  this.datePickerRangeInv.endDate
      this.invoiceDateFrom =  this.datePickerRangeInv.startDate
      this.invoiceDateTo =  this.datePickerRangeInv.endDate

      this.runExtendedFilterFromChild()

    },
    removeAutoCompleteFExpedisi (item) {
      const index = this.filterFexpedisies.indexOf(item.id)
      if (index >= 0) this.filterFexpedisies.splice(index, 1)
    },
    removeAutoCompleteFWarehouse (item) {
      const index = this.filterFwarehouses.indexOf(item.id)
      if (index >= 0) this.filterFwarehouses.splice(index, 1)
    },

    removeAutoCompleteFMaterial (item) {
      const index = this.filterFmaterials.indexOf(item.id)
      if (index >= 0) this.filterFmaterials.splice(index, 1)
    },
    lookupFDivision (fdivisionBean) {
      const str = this.itemsFDivision.filter(x => x.id===fdivisionBean)
      if (str.length>0){
        return str[0]
      }else {
        return undefined
      }
    },
    lookupFSalesman (fsalesmanBean) {
      const str = this.itemsFSalesman.filter(x => x.id===fsalesmanBean)

      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },
    lookupFCustomer (fcustomerBean) {
      const str = this.itemsFCustomer.filter(x => x.id===fcustomerBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },
    lookupFStore (fstoreBean) {
      const str = this.itemsFStore.filter(x => x.id===fstoreBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },
    lookupFExpedisi (fexpedisiBean) {
      const str = this.itemsFExpedisi.filter(x => x.id===fexpedisiBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },

    lookupFWarehouseKode1 (fwarehouseBean) {
      const str = this.itemsFWarehouse.filter(x => x.id===fwarehouseBean)
      if (str.length>0){
        return str[0].kode1
      }else {
        return '-'
      }
    },

    lookupFWarehouse (fwarehouseBean) {
      const str = this.itemsFWarehouse.filter(x => x.id===fwarehouseBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },

    lookupFMaterialPcode (fmaterialBean) {
      const str = this.itemsFMaterial.filter(x => x.id===fmaterialBean)
      if (str.length>0){
        return `${str[0].pcode}`
      }else {
        return '-'
      }
    },
    lookupFMaterial (fmaterialBean) {
      const str = this.itemsFMaterial.filter(x => x.id===fmaterialBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },
    lookupEStatusPengiriman (statusPengiriman) {
      const str = this.itemsEStatusPengiriman.filter(x => x.id===statusPengiriman)
      if (str.length>0){
        return `${str[0].shortDesc}`
      }else {
        return '-'
      }
    },
    lookupEStatusPengirimanColor (statusPengiriman) {
      const str = this.itemsEStatusPengiriman.filter(x => x.id===statusPengiriman)
      if (str.length>0){
        return `${str[0].color}`
      }else {
        return '-'
      }
    },
    lookupImageUrl(item){
      if (item.avatarImage===undefined || item.avatarImage===""){
        return require('@/assets/images/no_image_available.jpeg')
      }else {
        return FileService.image_url_verylow(item.avatarImage)
      }
    },
    lookupImageUrlLazy(){
      return require('@/assets/images/no_image_available.jpeg')
    },
    formattedCurrencyValue(prefix, value){
      if(! value){ return "0"}
      return prefix + "" + parseFloat(value).toLocaleString()
    },

    dateFormattedDatefns(value){
      return dateFormattedDatefns(value)
    },
    dateFormattedDatefnsShort(value){
      return dateFormattedDatefnsShort(value)
    },
    dateFormattedDatefnsWithTime(value){
      return dateFormattedDatefnsWithTime(value)
    },
    dateFormattedDatefnsWithTimeShort(value){
      return dateFormattedDatefnsWithTimeShort(value)
    },

    stringMaxLenght(value){
      let str = value
      if (value.length>6) str = value.substr(0, 5)
      return str
    }
  }
}
</script>

<style scoped>

</style>